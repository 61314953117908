<template>
  <v-row justify="center">
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      max-height="800px"
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="pb-0"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img
              v-if="layout === 'AdminDashboardLayout'"
              src="@/assets/teachermodallogo.svg"
              height="85"
              width="100"
            />
            <img
              v-if="layout === 'CampaignDashboardLayout'"
              src="@/assets/DistrictCampaign/teacherManagementModalLogo.svg"
              height="85"
              width="100"
              class="mb-2"
            />
          </div>
          <div class="ml-7 mb-3 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            @click="toggleAddEditViewTeacherModal({ show: false })"
            style="cursor: pointer; margin-top: -80px; margin-right: -15px"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-form ref="teacherForm" lazy-validation>
          <v-card-text class="pb-0">
            <v-row no-gutters justify="center">
              <v-col cols="12" class="px-3">
                <p
                  style="color: rgba(0, 0, 0, 0.6); font-size: 17px; padding: 0"
                >
                  Teacher type
                </p>
                <v-radio-group
                  class="radio-group py-0"
                  small
                  row
                  v-model="teacherType"
                >
                  <v-radio
                    :disabled="formLoading"
                    color="#2C1963"
                    label="General"
                    value="general"
                  ></v-radio>
                  <v-radio
                    :disabled="formLoading"
                    color="#2C1963"
                    label="Physical education(PE)"
                    value="pe"
                  ></v-radio>
                  <v-radio
                    :disabled="formLoading"
                    color="#2C1963"
                    label="Principal"
                    value="principal"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col class="px-3" cols="6">
                <v-text-field
                  label="Teacher Name"
                  outlined
                  dense
                  v-model="teacherName"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
              <v-col class="px-3" cols="6">
                <v-text-field
                  label="Display Name"
                  outlined
                  dense
                  v-model="displayName"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no no-gutters justify="center">
              <v-col cols="12" class="px-3">
                <v-text-field
                  label="Teacher Email"
                  outlined
                  dense
                  v-model="teacherEmail"
                  :rules="[rules.required, rules.email]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters justify="left">
              <v-col class="px-3" cols="6">
                <v-text-field
                  label="Goal"
                  outlined
                  dense
                  v-model="teacherGoal"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  type="number"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#067605">mdi-currency-usd</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col class="px-3" cols="6" v-if="teacherType === 'general'">
                <v-autocomplete
                  label="Grade"
                  v-model="grade"
                  :items="gradeList"
                  outlined
                  dense
                  :rules="[rules.required]"
                  item-text="grade_title"
                  item-value="id"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="12" class="px-3">
                <v-textarea
                  outlined
                  height="80px"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  v-model="specialMessage"
                  label="Special message by teacher"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pt-0 pb-5 px-7">
            <v-spacer></v-spacer>
            <v-btn
              v-if="type == 'add'"
              :loading="formLoading"
              class="rounded-lg dialogAction-btnText py-5 px-7"
              dark
              color="#38227A"
              @click="submitForm"
            >
              <span>Add Teacher</span>
            </v-btn>
            <v-btn
              :loading="formLoading"
              v-if="type == 'edit'"
              class="rounded-lg dialogAction-btnText py-5 px-7"
              dark
              color="#38227A"
              @click="submitForm"
            >
              <span>Save</span></v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RULES from "@/common/fieldRules";
import {
  API_ADMIN_TEACHER_MANAGEMENT_GET,
  API_ADMIN_TEACHER_MANAGEMENT_UPDATE,
  API_ADMIN_TEACHER_MANAGEMENT_POST,
  API_MASTER_GET_GRADE,
  API_MASTER_GET_MESSAGE,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import authToken from "@/common/authToken";
export default {
  name: "AddEditTeacherModal",
  components: {},
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      teacherType: "general",
      teacherName: "",
      displayName: "",
      teacherEmail: "",
      teacherGoal: null,
      grade: null,
      gradeList: [],
      specialMessage: "",
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "teacherManagement/getShow",
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    layout() {
      return this.$route.meta.layout;
    },
    userTeam() {
      return authToken.decodedToken().user_team;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddEditViewTeacherModal({ show: value });
      },
    },
    type() {
      return this.$store.state.teacherManagement.addEditViewModal.type;
    },
    toastMessage(){
      if(this.type==="add"){
        return "Teacher Added"
      }else{
        return "Teacher Updated"
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add new Teacher";
        case "edit":
          return "Edit Teacher";
        default:
          return "";
      }
    },
  },
  watch: {
    show(value) {
      console.log(value, "watching");
      if (value) {
        this.openCallModal();
      } else {
        this.closeCallModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleAddEditViewTeacherModal: "teacherManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    openCallModal() {
      console.log("in open call modal");
      this.loading = true;
      this.getGrade();
      if(this.type==="add"){
        this.teacherGoal = this.selectedCampaignData.campaignData.teacher_default_goal
      }
    },
    closeCallModal() {
      this.teacherType = "general";
      this.teacherName = "";
      this.displayName = "";
      this.teacherEmail = "";
      this.teacherGoal = "";
      this.grade = null;
      this.specialMessage = "";
    },
    getGrade() {
      const self = this;

      self.loading = true;

      const successHandler = (res) => {
        const data = res.data;
        console.log(data, "grades");
        self.gradeList = data.grade_list;
      };
      const failureHandler = (res) => {
        const data = res.data;
        console.log(data);
      };
      const finallyHandler = () => {
        if (this.type === "edit") {
          this.getTeacherDetails();
        } else {
          this.getSpecialMessage()
          self.loading = false;
        }
      };
      const formJson = {};
      Axios.request_GET(
        API_MASTER_GET_GRADE,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getSpecialMessage(){
      const successHandler = (res)=>{
        console.log(res.data.special_message_list[0],"specialMessage")
        if(res.data.special_message_list[0]){
          this.specialMessage = res.data.special_message_list[0].message
        }
      };
      const failureHandler = (res)=>{
        console.log(res)
      }
      let formData ={};
      formData["message_for"] = "teacher"
      formData["message_type"] = "special_message"
      formData["campaign"] = this.selectedCampaignData.campaignData.id;
      formData["school"] = this.selectedCampaignData.organizationData.id;
      Axios.request_GET(
        API_MASTER_GET_MESSAGE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    getTeacherDetails() {
      const self = this;

      const successHandler = (res) => {
        let data = res.data;
        console.log(data);
        self.teacherType = data.teacher_detail.teacher_type;
        self.teacherName = data.teacher_detail.teacher_name;
        self.displayName = data.teacher_detail.display_name;
        self.teacherEmail = data.teacher_detail.teacher_email;
        self.teacherGoal = data.teacher_detail.teacher_goal;
        self.grade = data.teacher_detail.grade;
        self.specialMessage = data.teacher_detail.teacher_special_message;
        self.loading = false;
      };
      const failureHandler = (res) => {
        const data = res.data;
        console.log(data, "failed");
        self.loading = false;
      };
      let formData = {};
      formData["teacher_id"] =
        this.$store.state.teacherManagement.addEditViewModal.teacherID;
      console.log(formData);
      Axios.request_GET(
        API_ADMIN_TEACHER_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitForm() {
      if (this.$refs.teacherForm.validate()) {
        const self = this;
        self.formLoading = true;

        const successHandler = (res) => {
          console.log(res.data);
          self.formLoading = false;
          this.toggleAddEditViewTeacherModal({ show: false });
          this.$emit("reload");
          this.showToast({
            message: this.toastMessage,
            color: "s",
          });
        };
        const failureHandler = (res) => {
          console.log(res);
          self.formLoading = false;
          this.showToast({
            message: res,
            color: "e",
          });
        };
        let formData = {};
        formData["teacher_type"] = this.teacherType;
        formData["teacher_name"] = this.teacherName;
        formData["display_name"] = this.displayName;
        formData["teacher_email"] = this.teacherEmail;
        formData["teacher_goal"] = this.teacherGoal;
        if (this.teacherType === "general") {
          formData["grade"] = this.grade;
        }

        formData["teacher_special_message"] = this.specialMessage;

        if (this.type == "edit") {
          formData["teacher_id"] =
            this.$store.state.teacherManagement.addEditViewModal.teacherID;
        }else{
          formData["campaign"] = this.selectedCampaignData.campaignData.id;
          if(this.selectedCampaignData.campaignData.campaign_type==="district"){
            formData["school_id"] = this.selectedCampaignData.organizationData.id;
          }
          
        }
        console.log(this.type)
        console.log(formData);
        if (this.type == "edit") {
          /**
           * API Call for UPDATE Teacher
           */
          Axios.request_PATCH(
            API_ADMIN_TEACHER_MANAGEMENT_UPDATE,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
        if (this.type === "add") {
          /**
           * API Call for POST Teacher
           */
          Axios.request_POST(
            API_ADMIN_TEACHER_MANAGEMENT_POST,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
      }
    },
    visitCampaign() {
      console.log("you have visited campaign");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
</style>
